const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://yczb6aejr1.execute-api.us-east-1.amazonaws.com/prod',
    TARGET_API_URL: 'https://wk752zu91i.execute-api.us-east-1.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.riotinto.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.riotinto.forwoodsafety.com'
  }
};

export default config;